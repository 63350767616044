import { Send } from "lucide-react";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

const SignUpStatusCard = ({
  status,
  email,
}: {
  status: string;
  email: string | null;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="max-w-md bg-white p-6 space-y-6">
      <Card className="max-w-md shadow-lg">
        <CardHeader>
          <CardTitle className="text-3xl font-bold text-center">
            {t(`signUpStatus.${status}.title`)}
          </CardTitle>
          <CardDescription className="text-center">
            <Trans
              i18nKey={`signUpStatus.${status}.description`}
              components={{ strong: <strong /> }}
              values={{ email: email }}
            />
          </CardDescription>
        </CardHeader>
        <CardContent className="flex justify-center">
          <Button
            className="w-full text-white"
            onClick={() => {
              status === "success" ? navigate("/login") : navigate("/help");
            }}
            type="submit"
          >
            <div className="flex items-center justify-center">
              <Send size="16" className="mr-2" />
              {t(`signUpStatus.${status}.button`)}
            </div>
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default SignUpStatusCard;
