// Function to calculate days remaining and determine color
export const getSubscriptionDateInfo = (validUntil: Date) => {
  const today = new Date();

  // Calculate the difference in days
  const diffTime = validUntil.getTime() - today.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  // Determine color based on days remaining
  let textColorClass = "text-green-600";
  if (diffDays <= 5) {
    textColorClass = "text-red-600";
  } else if (diffDays <= 10) {
    textColorClass = "text-orange-500";
  }

  return {
    formattedDate: validUntil.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }),
    daysRemaining: diffDays,
    textColorClass,
  };
};
