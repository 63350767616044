import { FormEvent, useState } from "react";
import { Button } from "../ui/button";
import { Label } from "../ui/label";
import { Input } from "../ui/input";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import { getLoginErrorAlert as LoginErrorAlert } from "../ErrorHandler/ErrorHandler";
import { Send } from "lucide-react";
import { ApiError } from "src/types/errors.types";
import { ApiClient } from "src/types/ApiClient";
import { useNavigate } from "react-router-dom";

export default function LoginCard({
  onSuccess,
  apiClient,
}: {
  onSuccess: (email: string) => void;
  apiClient: ApiClient;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [apiError, setApiError] = useState<ApiError | undefined>();
  const [showLoader, setShowLoader] = useState(false);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!email) {
      return;
    }
    setShowLoader(true);

    const { error } = await apiClient.login(email);
    setShowLoader(false);
    if (error) {
      setApiError(error);
      return;
    }
    onSuccess(email);
  };

  return (
    <div className="max-w-md bg-white p-6 space-y-6">
      <div className="space-y-2 text-center">
        <h1 className="text-3xl font-bold">{t(`loginCard.head`)}</h1>
        <Alert severity="info">{t(`loginCard.userNotice`)}</Alert>
        {apiError && <LoginErrorAlert apiError={apiError!} />}
      </div>
      <div className="flex flex-col">
        <form className="space-y-2 gap-6" id="form">
          <div className="grid gap-2">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              placeholder="m@example.com"
              required
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="">
            <Button
              disabled={showLoader || !email}
              className="w-full text-white"
              onClick={handleSubmit}
              type="submit"
            >
              <div className="flex items-center justify-center">
                {showLoader ? (
                  <div className="animate-spin h-6 w-6 rounded-full border-t-4 border-gray-900 mr-2" />
                ) : (
                  <Send size="16" className="mr-2" />
                )}
                {t(`loginCard.sendToken`)}
              </div>
            </Button>
          </div>
        </form>
        <div className="relative text-center mt-6 text-sm  after:absolute after:inset-0 after:top-1/2 after:z-0 after:flex after:items-center after:border-t after:border-border">
          <span className="relative z-10 bg-background px-2">
            {t("loginCard.signUpMessage")}
          </span>
        </div>
        <div className="my-6 flex justify-center">
          <Button
            variant="outline"
            className="w-full flex items-center justify-center"
            disabled={showLoader}
            onClick={() => (navigate('/signup'))}
          >
            {t("loginCard.signUpButton")}

            <svg
              fill="#000000"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              className="ml-2 h-8 w-8 mt-2" // Added sizing and margin
            >
              <path d="M10.25 2.24a5.79 5.79 0 0 0-4 1.63 4.48 4.48 0 1 0 0 8.26 5.76 5.76 0 1 0 4-9.89zm0 10.24A4.49 4.49 0 0 1 5.76 8H4.48a5.74 5.74 0 0 0 .89 3.07 3.29 3.29 0 0 1-.88.13 3.2 3.2 0 0 1 0-6.4A3.2 3.2 0 0 1 7.69 8H9a4.42 4.42 0 0 0-1.63-3.43 4.48 4.48 0 1 1 2.88 7.91z" />
            </svg>
          </Button>
        </div>
        <div className="text-center">
          <p className="text-zinc-500 dark:text-zinc-400">
            {t(`loginCard.preTermsAndConditions`)}
            <a className="text-blue-500 hover:text-blue-700" href="/terms">
              {" "}
              {t(`loginCard.terms`)}
            </a>{" "}
            {t(`loginCard.and`)}{" "}
            <a className="text-blue-500 hover:text-blue-700" href="/privacy">
              {t(`loginCard.privacy`)}
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}
