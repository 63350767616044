import { useTranslation } from "react-i18next";
import { Button } from "../ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { FormEvent, useState } from "react";
import { TIENDANUBE_AUTH_LINK } from "src/constants";
import { Send } from "lucide-react";

const SignUpCard = () => {
  const { t } = useTranslation();
  const [showLoader, setShowLoader] = useState(false);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setShowLoader(true);
    window.location.href = TIENDANUBE_AUTH_LINK;
  };

  return (
    <div className="max-w-md bg-white p-6 space-y-6">
      <h1 className="text-3xl md:text-4xl font-bold text-bundle-teal mb-8 text-center">
        {t(`signUpCard.title`)}
      </h1>
      <Card className=" max-w-sm border shadow-lg rounded-lg border-gray-200 dark:border-gray-700">
        <CardHeader>
          <CardTitle className="text-2xl font-bold text-center">
            {t(`signUpCard.cardTitle`)}
          </CardTitle>
          <CardDescription className="text-center">
            {t(`signUpCard.cardDescription`)}
          </CardDescription>
        </CardHeader>
        <CardContent className="flex justify-center">
          <Button
            disabled={showLoader}
            className="w-full text-white"
            onClick={handleSubmit}
            type="submit"
          >
            <div className="flex items-center justify-center">
              {showLoader ? (
                <div className="animate-spin h-6 w-6 rounded-full border-t-4 border-gray-900 mr-2" />
              ) : (
                <Send size="16" className="mr-2" />
              )}
              {t(`signUpCard.submitButton`)}
            </div>
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default SignUpCard;
