// src/Blog.jsx
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getFormattedDate } from "src/lib/utils";
import { BlogPostData } from "src/types/BlogPost";
import NavBar from "../NavBar";
import { useTranslation } from "react-i18next";

const Blog = () => {
  const { t } = useTranslation();
  const [posts, setPosts] = useState<BlogPostData[]>([]);

  useEffect(() => {
    fetch("/posts.json")
      .then((response) => response.json())
      .then((data) => setPosts(data));
  }, []);

  return (
    <main>
      <NavBar />
      <div className="max-w-2xl mx-auto px-4 py-12">
        <header className="mb-12">
          <h1 className="text-4xl font-bold tracking-tight mb-4">
            {t("blog.title")}
          </h1>
          <p className="text-xl text-muted-foreground">
            {t("blog.description")}
          </p>
        </header>

        <section>
          {posts.length > 0 ? (
            posts.map((post) => (
              <PostCard
                key={post.slug}
                title={post.title}
                date={post.date}
                description={post.description}
                slug={post.slug}
              />
            ))
          ) : (
            <p className="text-muted-foreground">{t("blog.emptyPage")}</p>
          )}
        </section>
      </div>
    </main>
  );
};

interface PostCardProps {
  title: string;
  date: string;
  description: string;
  slug: string;
}

function PostCard({ title, date, description, slug }: PostCardProps) {
  return (
    <article className="mb-8 rounded-md border-b border-gray-800 pb-8 hover:bg-gray-300 transition-colors last:border-0">
      <Link to={`/blog/${slug}`} className="block group">
        <h2 className="text-2xl font-semibold tracking-tight mb-2 group-hover:text-primary transition-colors">
          {title}
        </h2>
        <time
          dateTime={date}
          className="text-sm text-muted-foreground block mb-3"
        >
          {getFormattedDate(date)}
        </time>
        {description && <p className="text-muted-foreground">{description}</p>}
      </Link>
    </article>
  );
}

export default Blog;
