import useSignOut from "react-auth-kit/hooks/useSignOut";

const Logout = () => {
  const signOut = useSignOut();

  signOut();
  return (
    <div className="animate-spin h-6 w-6 rounded-full border-t-4 border-gray-900 mr-2" />
  );
};

export default Logout;
