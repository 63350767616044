import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import logo from "../../resources/logo.png";
import { NavLink } from "react-router-dom";
import { Instagram, Mail, MailCheck } from "lucide-react";
import { Button } from "../ui/button";
import { useTranslation } from "react-i18next";
import { useForm } from "@formspree/react";
import { Input } from "../ui/input";

function Copyright() {
  return (
    <p className="text-sm text-muted-foreground">
      {"Copyright © "}
      <a href="https://yass.tech/">YASS Technologies</a>
      &nbsp;
      {new Date().getFullYear()}
    </p>
  );
}

export default function Footer() {
  const { t } = useTranslation();
  const [state, handleSubmit] = useForm("xanwybve");

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: "center", md: "left" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            minWidth: { xs: "100%", sm: "60%" },
          }}
        >
          <Box sx={{ width: { xs: "100%", sm: "60%" } }}>
            <NavLink to="/" className="flex items-center">
              <img src={logo} alt="Bundle logo" width="120px" />
            </NavLink>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ fontWeight: 600, mt: 2 }}
            >
              {t("footer.newsletter")}
            </Typography>
            <Stack direction="row" spacing={1} useFlexGap>
              <form onSubmit={handleSubmit}>
                <Input
                  id="name"
                  type="hidden"
                  required
                  name="name"
                  value="Suscribe Newsletter"
                />
                <Input
                  id="store"
                  type="hidden"
                  required
                  name="store"
                  value="Suscribe Newsletter"
                />
                <Input
                  id="message"
                  type="hidden"
                  required
                  name="message"
                  value="Suscribe Newsletter"
                />
                <TextField
                  id="email"
                  name="email"
                  hiddenLabel
                  size="small"
                  type="email"
                  required
                  variant="outlined"
                  fullWidth
                  aria-label="Enter your email address"
                  placeholder="mail@example.com"
                  sx={{ width: "250px" }}
                />
                <Button
                  className="self-end text-white ml-2"
                  type="submit"
                  disabled={state.submitting || state.succeeded}
                >
                  <div className="flex items-center justify-center">
                    {state.succeeded && <MailCheck />}
                    {state.submitting && (
                      <div className="animate-spin h-6 w-6 rounded-full border-t-4 border-gray-900 mr-2" />
                    )}
                    {!state.succeeded && t("footer.subscribe")}
                  </div>
                </Button>
              </form>
            </Stack>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            {t("footer.product")}
          </Typography>
          <Link color="text.secondary" variant="body2" href="/">
            {t("footer.features")}
          </Link>
          <Link color="text.secondary" variant="body2" href="/help">
            {t("footer.howItWorks")}
          </Link>
          <Link color="text.secondary" variant="body2" href="/pricing">
            {t("footer.pricing")}
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            href="/help#faqs-section"
          >
            {t("footer.faqs")}
          </Link>
        </Box>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: "medium" }}>
            {t("footer.help")}
          </Typography>
          <Link color="text.secondary" variant="body2" href="/terms">
            {t("footer.terms")}
          </Link>
          <Link color="text.secondary" variant="body2" href="/privacy">
            {t("footer.privacy")}
          </Link>
          <Link
            color="text.secondary"
            variant="body2"
            href="/help#contact-section"
          >
            {t("footer.contact")}
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column-reverse", sm: "row" },
          justifyContent: "space-between",
          pt: { xs: 4, sm: 8 },
          width: "100%",
          borderTop: "1px solid",
          borderColor: "divider",
        }}
      >
        <div>
          <Link color="text.secondary" variant="body2" href="/privacy">
            {t("footer.fullPrivacy")}
          </Link>
          <Typography sx={{ display: "inline", mx: 0.5, opacity: 0.5 }}>
            &nbsp;•&nbsp;
          </Typography>
          <Link color="text.secondary" variant="body2" href="/terms">
            {t("footer.fullTerms")}
          </Link>
          <Copyright />
        </div>
        <a
          href="https://appgentina.com.ar/producto/bundles?ref=badge"
          title="Bundles | Appgentina"
        >
          <img
            src="https://appgentina.com.ar/embed-svg/bundles"
            alt="Bundles | Appgentina"
            style={{ width: "229px", height: "54px" }}
            width="229"
            height="54"
          />
        </a>
        <Stack
          direction="row"
          spacing={1}
          useFlexGap
          sx={{ justifyContent: "left", color: "text.secondary" }}
        >
          <IconButton
            color="inherit"
            size="small"
            href="mailto:contact@yass.tech"
            aria-label="Mail"
            sx={{ alignSelf: "center" }}
          >
            <Mail />
          </IconButton>
          <IconButton
            color="inherit"
            size="small"
            href="https://www.instagram.com/yass.technologies"
            aria-label="Instagram"
            sx={{ alignSelf: "center" }}
          >
            <Instagram />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
