import { useTranslation } from "react-i18next";
import NavBar from "../NavBar";
import { Button } from "../ui/button";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Check } from "lucide-react";

const Plans = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const plans = [
    {
      name: "STARTER",
      originalPrice: "USD $10.00",
      discountPrice: "USD $7.00",
      description: t(`plans.starterDescription`),
      benefits: [t(`plans.starter1`), t(`plans.starter2`), t(`plans.starter3`)],
      cta: true,
      ctaLabel: t(`plans.starterCtaLabel`),
    },
    {
      name: "PROFESSIONAL",
      originalPrice: "USD $50.00",
      discountPrice: "USD $35.00",
      description: t(`plans.professionalDescription`),
      benefits: [
        t(`plans.starter1`),
        t(`plans.starter2`),
        t(`plans.professional3`),
      ],
      cta: true,
      ctaLabel: t(`plans.starterCtaLabel`),
    },
    {
      name: "ADVANCED",
      originalPrice: "USD $125.00",
      discountPrice: "USD $87.50",
      description: t(`plans.advancedDescription`),
      benefits: [
        t(`plans.starter1`),
        t(`plans.starter2`),
        t(`plans.advanced3`),
      ],
      cta: true,
      ctaLabel: t(`plans.starterCtaLabel`),
    },
  ];

  return (
    <main>
      <NavBar />
      <div className="container mx-auto py-16 px-4">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold tracking-tight mb-4">
            {t("plans.title")}
          </h1>
          <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
            {t("plans.intro")}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {plans.map((plan) => {
            return (
              <Card
                id={plan.name}
                className="flex flex-col border-2 hover:border-primary/50 transition-all"
              >
                <CardHeader className="flex flex-col space-y-1.5">
                  <CardTitle className="text-2xl">{plan.name}</CardTitle>
                  <CardDescription>{plan.description}</CardDescription>
                  <div className="mt-4 flex flex-col">
                    <div className="flex items-center justify-center gap-2">
                      <span className="text-muted-foreground line-through text-lg">
                        ${plan.originalPrice}
                      </span>
                      <span className="bg-primary/10 text-primary text-xs px-2 py-0.5 rounded-full font-medium">
                        30% OFF
                      </span>
                    </div>
                    <div>
                      <span className="text-3xl font-bold">
                        ${plan.discountPrice}
                      </span>
                      <span className="text-muted-foreground ml-1">
                        /{t("plans.paymentCycle")}
                      </span>
                    </div>
                    {
                      <p className="text-sm text-primary font-medium">
                        {t("plans.freeCtaLabel")}
                      </p>
                    }
                  </div>
                </CardHeader>
                <CardContent className="flex-grow">
                  <ul className="space-y-3">
                    {plan.benefits.map((benefit, index) => (
                      <li key={index} className="flex items-center">
                        <Check className="h-5 w-5 text-primary mr-2 flex-shrink-0" />
                        <span>{benefit}</span>
                      </li>
                    ))}
                  </ul>
                </CardContent>
                <CardFooter>
                  <Button className="w-full" onClick={() => navigate("/login")}>
                    {t("plans.starterCtaLabel")}
                  </Button>
                </CardFooter>
              </Card>
            );
          })}
        </div>
        {/* Enterprise Section */}
        <div className="mt-20 text-center max-w-3xl mx-auto p-8 border rounded-lg bg-muted/30">
          <h2 className="text-2xl font-bold mb-4">Enterprise</h2>
          <p className="text-muted-foreground mb-6">
            {t("plans.enterpriseDescription")}
          </p>

          <a href="/help#contact-section">
            <Button variant="outline" size="lg">
              {t("plans.enterpriseCtaLabel")}
            </Button>
          </a>
        </div>

        {/* Payment method disclaimer */}
        <div className="text-center mt-16">
          <p className="text-xl text-muted-foreground max-w-2xl mx-auto">
            {t("plans.payment")}
          </p>
        </div>
        <Footer />
      </div>
    </main>
  );
};

export default Plans;
