import { useState } from "react";
import NavBar from "../NavBar";
import TokenCardPage from "./TokenCardPage";
import LoginCard from "./LoginCard";
import { ApiClient } from "src/types/ApiClient";

const LoginPage = ({ apiClient }: { apiClient: ApiClient }) => {
  const [email, setEmail] = useState<string | undefined>();

  return (
    <main className="h-screen flex flex-col">
      <NavBar />
      <div className="h-full flex items-center justify-center">
        {!email ? (
          <LoginCard onSuccess={setEmail} apiClient={apiClient} />
        ) : (
          <TokenCardPage email={email} apiClient={apiClient} />
        )}
      </div>
    </main>
  );
};

export default LoginPage;
