import React, { createContext, useContext, useState, useEffect } from "react";
import { ReactNode } from "react";
import { ApiClient } from "src/types/ApiClient";
import useAuthHeader from "react-auth-kit/hooks/useAuthHeader";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";

interface StoreData {
  name: string;
  url: string;
  status: "ACTIVE" | "EXPIRED";
  subscriptionType: "STARTER" | "PROFESSIONAL" | "ADVANCED" | "ENTERPRISE";
  subscriptionValidUntil: Date;
}

interface StoreDataContextType {
  storeData: StoreData | null;
  setStoreData: React.Dispatch<React.SetStateAction<StoreData | null>>;
}

const StoreDataContext = createContext<StoreDataContextType | undefined>(
  undefined,
);

export const useStoreData = () => {
  const context = useContext(StoreDataContext);
  if (!context) {
    throw new Error("useStore must be used within a StoreProvider");
  }
  return context;
};

export default function StoreContextProvider({
  children,
  apiClient,
}: {
  children: ReactNode;
  apiClient: ApiClient;
}) {
  const [storeData, setStoreData] = useState<StoreData | null>(null);
  const token = useAuthHeader();
  const loggedIn = useIsAuthenticated();

  useEffect(() => {
    // You can make an API call here to fetch the store data when the user is logged in
    const fetchStore = async () => {
      if (loggedIn()) {
        try {
          if (!token) {
            return;
          }

          const storeRes = await apiClient.getStore(token);

          if (!storeRes || !storeRes.store) {
            return;
          }

          setStoreData({
            name: storeRes.store?.name.es || "name",
            url: storeRes.store?.domain || "url",
            status: storeRes.store?.subscription.status,
            subscriptionType: storeRes.store?.subscription.subscriptionType,
            subscriptionValidUntil: new Date(
              storeRes.store?.subscription.dueDate,
            ),
          });
        } catch (error) {
          console.error("Error fetching store:", error);
        }
      }
    };

    fetchStore();
  }, []);

  return (
    <StoreDataContext.Provider value={{ storeData, setStoreData }}>
      {children}
    </StoreDataContext.Provider>
  );
}
