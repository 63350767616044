import { useSearchParams } from "react-router-dom";
import NavBar from "../NavBar";
import SignUpCard from "./SignUpCard";
import SignupStatusCard from "./SignUpStatusCard";

const SingUpPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const status = searchParams.get("state");
  const email = searchParams.get("email");

  console.log("status", status);
  return (
    <main className="h-screen flex flex-col">
      <NavBar />
      <div className="h-full flex items-center justify-center">
        {status === null ? (
          <SignUpCard />
        ) : (
          <SignupStatusCard status={status} email={email} />
        )}
      </div>
    </main>
  );
};

export default SingUpPage;
