import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { BlogPostData } from "src/types/BlogPost";
import { ChevronLeft, ExternalLink, Play } from "lucide-react";
import { getFormattedDate } from "src/lib/utils";
import { useTranslation } from "react-i18next";
import NavBar from "../NavBar";

const BlogPost = () => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const [post, setPost] = useState<BlogPostData>();

  useEffect(() => {
    fetch(`/posts.json`)
      .then((response) => response.json())
      .then((data) => data.find((post: BlogPostData) => post.slug === slug))
      .then((post) => setPost(post));
  }, [slug]);

  return (
    <main>
      <NavBar />
      <div className="max-w-2xl mx-auto px-4 py-12">
        <Link
          to="/blog"
          className="inline-flex items-center text-sm text-muted-foreground hover:text-foreground mb-8"
        >
          <ChevronLeft className="mr-1 h-4 w-4" />
          {t("blog.backToAllPosts")}
        </Link>

        {post && (
          <article>
            <div className="mb-8 p-4 bg-muted rounded-lg border border-border">
              <div className="flex items-start gap-4">
                <div className="bg-primary/10 p-2 rounded-full">
                  <Play className="h-5 w-5 text-primary" />
                </div>
                <div className="flex-1">
                  <h3 className="font-medium mb-1">Prefer to watch instead?</h3>
                  <p className="text-sm text-muted-foreground mb-3">
                    This article is also available as a video walkthrough.
                  </p>
                  <a
                    href={post.videoUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="inline-flex items-center gap-2 text-sm font-medium text-primary hover:underline"
                  >
                    Watch video <ExternalLink className="h-3 w-3" />
                  </a>
                </div>
              </div>
            </div>
            <header className="mb-8">
              <h1 className="text-3xl font-bold tracking-tight mb-2">
                {post.title}
              </h1>
              <time
                dateTime={post.date}
                className="text-sm text-muted-foreground block"
              >
                {getFormattedDate(post.date)}
              </time>
            </header>
            <div className="prose prose-gray dark:prose-invert max-w-none">
              <ReactMarkdown>{post.content}</ReactMarkdown>
            </div>
          </article>
        )}
      </div>
    </main>
  );
};

export default BlogPost;
