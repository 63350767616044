import { ApiClient } from "src/types/ApiClient";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "../ui/card";
import { Separator } from "../ui/separator";
import { Badge } from "../ui/badge";
import NavBar from "../NavBar";
import { useStoreData } from "src/providers/StoreContextProvider";
import { getSubscriptionDateInfo } from "src/utils/StoreUtils";
import Loader from "../Loader/Loader";
import { Alert, AlertTitle, AlertDescription } from "../ui/alert";
import { AlertCircle } from "lucide-react";
import { useTranslation } from "react-i18next";

export default function ProfileView({ apiClient }: { apiClient: ApiClient }) {
  // This would typically come from an API or props
  const { storeData } = useStoreData();
  const { t } = useTranslation();

  // Function to render the appropriate status badge
  const renderStatusBadge = (status: string) => {
    switch (status.toLowerCase()) {
      case "active":
        return (
          <Badge className="bg-green-500 hover:bg-green-600">
            {t(`profile.active`)}
          </Badge>
        );
      case "pending":
        return (
          <Badge className="bg-yellow-500 hover:bg-yellow-600">
            {t(`profile.pending`)}
          </Badge>
        );
      case "suspended":
        return (
          <Badge className="bg-red-500 hover:bg-red-600">
            {t(`profile.suspended`)}
          </Badge>
        );
      default:
        return <Badge variant="outline">{status}</Badge>;
    }
  };

  // Function to render subscription type with appropriate styling
  const renderSubscriptionType = (type: string) => {
    switch (type.toLowerCase()) {
      case "starter":
        return (
          <span className="text-muted-foreground">{t(`profile.starter`)}</span>
        );
      case "professional":
        return (
          <span className="text-blue-500 font-medium">
            {t(`profile.professional`)}
          </span>
        );
      case "advanced":
        return (
          <span className="text-purple-500 font-medium">
            {t(`profile.advance`)}
          </span>
        );
      case "enterprise":
        return (
          <span className="text-amber-500 font-medium">
            {t(`profile.enterprice`)}
          </span>
        );
      default:
        return <span>{type}</span>;
    }
  };

  return (
    <>
      <NavBar />
      {storeData != null ? (
        <>
          {(() => {
            const daysRemaining = getSubscriptionDateInfo(
              storeData.subscriptionValidUntil,
            ).daysRemaining;
            if (daysRemaining > 10) {
              return <></>;
            }
            if (daysRemaining > 0) {
              return (
                <div className="max-w-2xl mx-auto mt-8">
                  <Alert variant="destructive">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>{t(`profile.alertTitle`)}</AlertTitle>
                    <AlertDescription>
                      {daysRemaining === 1
                        ? t(`profile.alertDescSingle`)
                        : t(`profile.alertDescMultiple`, {
                            days: daysRemaining,
                          })}
                    </AlertDescription>
                  </Alert>
                </div>
              );
            }
            return (
              <div className="max-w-2xl mx-auto pt-8 px-4">
                <Alert variant="destructive">
                  <AlertCircle className="h-4 w-4" />
                  <AlertTitle>{t(`profile.alertTitleExpired`)}</AlertTitle>
                  <AlertDescription>
                    {t(`profile.alertExpired`)}
                  </AlertDescription>
                </Alert>
              </div>
            );
          })()}
          <div className="container mx-auto py-8 px-4">
            <Card className="max-w-2xl mx-auto">
              <CardHeader>
                <CardTitle className="text-2xl">{t(`profile.title`)}</CardTitle>
                <CardDescription>{t(`profile.description`)}</CardDescription>
              </CardHeader>
              <CardContent className="space-y-6">
                <div className="space-y-1.5">
                  <h3 className="text-sm font-medium text-muted-foreground">
                    {t(`profile.name`)}
                  </h3>
                  <p className="text-lg font-semibold">{storeData.name}</p>
                </div>

                {storeData.url && (
                  <div className="space-y-1.5">
                    <h3 className="text-sm font-medium text-muted-foreground">
                      {t(`profile.url`)}
                    </h3>
                    <div className="flex items-center gap-2">
                      <p className="text-lg text-blue-600 hover:underline">
                        <a
                          href={storeData.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {storeData.url}
                        </a>
                      </p>
                    </div>
                  </div>
                )}

                <Separator />

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="space-y-1.5">
                    <h3 className="text-sm font-medium text-muted-foreground">
                      {t(`profile.status`)}
                    </h3>
                    <div>{renderStatusBadge(storeData.status)}</div>
                  </div>

                  <div className="space-y-1.5">
                    <h3 className="text-sm font-medium text-muted-foreground">
                      {t(`profile.subsType`)}
                    </h3>
                    <div className="text-lg">
                      {renderSubscriptionType(storeData.subscriptionType)}
                    </div>
                  </div>

                  <div className="space-y-1.5 md:col-span-2">
                    <h3 className="text-sm font-medium text-muted-foreground">
                      {t(`profile.subsUntil`)}
                    </h3>
                    {storeData.subscriptionValidUntil && (
                      <div>
                        {(() => {
                          const {
                            formattedDate,
                            daysRemaining,
                            textColorClass,
                          } = getSubscriptionDateInfo(
                            storeData.subscriptionValidUntil,
                          );
                          return (
                            <div className="flex flex-col">
                              <span
                                className={`text-lg font-medium ${textColorClass}`}
                              >
                                {formattedDate}
                              </span>
                              <span className={`text-sm ${textColorClass}`}>
                                {daysRemaining > 0
                                  ? daysRemaining === 1
                                    ? t(`profile.dayRemaining`)
                                    : t(`profile.daysRemaining`, {
                                        days: daysRemaining,
                                      })
                                  : t(`profile.expired`)}
                              </span>
                            </div>
                          );
                        })()}
                      </div>
                    )}
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
}
